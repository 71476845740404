/* .swiper-pagination,
.swiper-button-prev,
.swiper-button-next,
.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
} */

.swiper:hover,
.swiper:focus {
  .swiper-button-prev,
  .swiper-button-next,
  .swiper-pagination {
    opacity: 1;
  }

  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    opacity: 0.125;
  }
}

.swiper-3d .swiper-slide-shadow {
  filter: blur(25px);
}

.swiper-creative .swiper-slide {
  overflow: inherit;
}

.swiper-slide.swiper-slide-visible.swiper-slide-fully-visible.swiper-slide-active
  h3,
.swiper-slide.swiper-slide-visible.swiper-slide-fully-visible.swiper-slide-active
  p {
  opacity: 1;
}
