@import "../../styles/globals.scss";
@import "../../styles/mixins.scss";
@import "../../styles/shared.scss";

.marquee {
  pointer-events: none;
}

.marqueeContainer {
  padding: 0;
  // width: fit-content;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  h2 {
    display: flex;
    align-items: center;
    margin: 0;
    white-space: nowrap;
    padding: $padding 0;
    // height: 1rem;
    font-weight: 900;
    line-height: $line-height--small;
    font-family: $font-family--titles;
    // mix-blend-mode: multiply;

    img {
      max-width: 72px;
      margin: 0 $padding;
      filter: grayscale(100%) sepia(90%);
    }
  }
}

.button {
  margin-top: $padding;
}

.footer {
  background-color: $black;
  mix-blend-mode: overlay;
  overflow: hidden;

  a {
    color: $white;
    text-decoration: none;
    transition: $animation;
    font-weight: $font-weight--bold;

    &:hover,
    &:focus {
      color: $red;
    }
  }

  &Social {
    /* padding-top: calc($padding * 1.5); */

    ul {
      display: flex;
      align-items: center;
    }

    ul,
    li {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    li {
      margin-right: calc($padding / 1.5);

      &:hover,
      &:focus {
        svg {
          fill: $red;
        }
      }
    }

    svg {
      fill: $white;
      width: 32px;
      max-height: 32px;
    }
  }

  &Inner {
    padding: calc($padding * 2);
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: stretch;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 100%;
    max-width: $max-width;
    margin: auto;
    // min-height: 100vh;

    @include breakpoint("max-width", $breakpoint-medium) {
      flex-direction: column;
      // flex-wrap: wrap-reverse;
      overflow: hidden;
    }
  }

  &Column {
    margin-bottom: calc($padding * 1.5);
    text-align: right;

    @include breakpoint("min-width", $breakpoint-medium) {
      width: calc(100% / 3);
      margin: 0;
    }

    address {
      // font-size: 1.8rem;
      // font-family: $font-family--mono;
      font-feature-settings: "tnum";
      font-variant-numeric: tabular-nums;
      letter-spacing: 1px;
      font-style: normal;
      color: $white;
      margin-top: calc($padding / 2);
    }
  }

  .marqueeContainer {
    h2 {
      font-size: 6rem;
      font-weight: $font-weight--light;
    }
  }

  // p {
  //   font-family: $font-family--titles;
  //   font-size: 1.6rem;
  //   line-height: 1.125;
  //   text-wrap: balance;
  //   text-wrap: balance;
  // }

  &Spacer {
    display: block;
    height: 1rem;
  }

  &Copyright {
    padding: calc($padding * 2);
    width: 100%;
    max-width: $max-width;
    margin: auto;

    p {
      font-size: 0.8rem;

      strong {
        font-weight: $font-weight--bold;
      }

      &:last-child {
        margin-bottom: calc($padding / 2);
      }
    }
  }

  .version {
    background-color: $white;
    color: $black;
    padding: calc($padding / 4) calc($padding / 2);
    // mix-blend-mode: multiply;
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    font-family: $font-family--titles;
    font-size: 0.8rem;

    &:hover,
    &:focus {
      background-color: rgba($black, 0.33);
      color: $white;
    }

    svg {
      width: 18px;
      height: 18px;
      fill: $white;
      margin-right: calc($padding / 4);
    }

    strong {
      font-weight: $font-weight--bold;
      margin-left: calc($padding / 4);
    }
  }

  .content {
    height: auto;

    @include breakpoint("max-width", $breakpoint-medium) {
      text-align: center;
    }
  }

  .article {
    align-items: center;
    justify-content: center;
  }
}
