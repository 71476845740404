@import "../../styles/globals.scss";
@import "../../styles/mixins.scss";
@import "../../styles/shared.scss";

.hideForSmall {
  @include breakpoint("max-width", $breakpoint-medium) {
    display: none !important;
  }
}

.banner {
  position: sticky;
  top: 0;
  padding: calc($padding * 2);
  font-size: 0.9rem;
  /* mix-blend-mode: overlay; */

  .button {
    display: flex;
    align-items: center;

    svg {
      fill: $black;
      width: 24px;
    }

    strong {
      color: $black;
    }

    &:hover,
    &:focus {
      background-color: $black;

      strong {
        color: $white;
      }

      svg {
        fill: $white;
      }
    }
  }

  .article {
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    @include breakpoint("max-width", $breakpoint-medium) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &Limit {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* height: 100vh; */
  }

  &Logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    padding-bottom: $padding;

    @include breakpoint("min-width", $breakpoint-medium) {
      align-items: flex-start;
      margin: 0;
    }

    h1 {
      margin: 0;
      line-height: 1;
      text-shadow: 0 0 4px rgba($black, 0.5);
    }

    p {
      font-weight: $font-weight--bold;
      line-height: 1;
    }
  }

  &TimeOpen {
    color: $white;
    display: inline-flex;
    align-items: center;

    @include breakpoint("min-width", $breakpoint-medium) {
      margin-left: calc($padding / 4);
    }

    p {
      font-weight: $font-weight--normal;
      text-shadow: 0 0 4px rgba($black, 0.5);
    }

    svg {
      width: 18px;
      height: 18px;
      fill: $white;
      margin-right: calc($padding / 4);
    }
  }

  &Location {
    max-width: 420px;
    display: inline-flex;
    align-items: center;
    margin-right: calc($padding / 4);
    margin-bottom: calc($padding * 2);
    text-wrap: balance;
    text-align: center;
    margin: auto;

    @include breakpoint("min-width", $breakpoint-medium) {
      text-align: right;
      margin: 0;
    }

    @include breakpoint("min-width", $breakpoint-medium) {
      margin-bottom: 0;
    }
  }
}

.spacer {
  width: 2rem;
}

.outer {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: calc($padding * 2);

  &Values {
    background-color: rgba($white, 0.25);
  }

  @include breakpoint("max-width", $breakpoint-small) {
    padding-left: $padding;
    padding-right: $padding;
  }

  // border: $border-width solid $black;

  @include breakpoint("max-width", $breakpoint-medium) {
    overflow: hidden;
  }

  @include breakpoint("min-width", $breakpoint-large) {
    padding: calc(3vh * 3) calc(3vh * 2);
  }

  .article {
    @include breakpoint("min-width", $breakpoint-medium) {
      width: calc(100% / 2);
    }

    &Header {
      position: sticky;
      top: calc($padding * 2);

      @include breakpoint("max-width", $breakpoint-small) {
        position: static;
      }

      &Number {
        font-size: 1.75rem;
        font-weight: $font-weight--bold;
        aspect-ratio: 4/3;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $white;
        color: $black;
        max-width: 80px;
        margin-top: 0;
        margin-bottom: $padding;
      }

      &Right {
        text-align: right;
      }

      &Title {
        font-weight: $font-weight--light;
        font-size: 3rem;

        @include breakpoint("max-width", $breakpoint-small) {
          margin-top: 0 !important;
        }

        @include breakpoint("min-width", $breakpoint-large) {
          font-size: 6rem;
        }
      }
    }
  }
}

.intro {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: stretch;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: calc($padding * 2);
  padding-top: 0;
  width: 100%;
  // mix-blend-mode: overlay;

  @include breakpoint("max-width", $breakpoint-medium) {
    flex-direction: row-reverse;
    flex-wrap: wrap-reverse;
    overflow: hidden;
  }

  // svg {
  //   fill: $black;
  //   max-width: 420.69px;
  //   width: 80%;
  //   // mix-blend-mode: overlay;
  // }

  h2,
  p {
    text-wrap: balance;
    text-shadow: 0 0 4px rgba($black, 0.5);
  }

  @include breakpoint("max-width", $breakpoint-medium) {
    h2 {
      font-size: 1.6rem;
    }
  }

  // bounce
  @keyframes bounce {
    0%,
    100% {
      transform: translateY(12.5%);
    }
    50% {
      transform: translateY(-25%);
    }
    animation-timing-function: ease-in;
  }

  .content {
    height: auto;
    font-weight: $font-weight--bold;

    &Inner {
      padding-bottom: 0;

      &Align {
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0.5;
        @include transition(all 0.333s ease-in-out);

        &:hover,
        &:focus {
          opacity: 1;
        }
      }

      svg {
        width: 42px;
        fill: $white;

        // bounce icon infinitely
        animation: bounce 2s infinite;
      }
    }
  }

  .article {
    align-items: center;
  }

  /* &Wrap {
    @include breakpoint("max-width", $breakpoint-medium) {
      min-height: 100vh;
    }
  } */

  &Background {
    /* position: absolute; */
    /* top: 120px; */
    /* left: 0; */
    width: 100%;
    /* height: calc(100vh - 120px); */
    padding: 20px;
    object-fit: cover;
    overflow: hidden;

    @include breakpoint("max-width", $breakpoint-small) {
      padding-top: 0;
      padding-bottom: 0;
    }

    @include transition(all 0.666s ease-in-out);
    /* -webkit-mask-image: -webkit-gradient(
      linear,
      left 90%,
      left bottom,
      from(rgba(0, 0, 0, 1)),
      to(rgba(0, 0, 0, 0))
    ); */

    &:hover,
    &:focus {
      filter: none;
    }
  }
}

.browserWindowOSX {
  box-shadow: $shadow-edges;
  border: 1px solid rgba($white, 0.125);
  width: 90%;
  max-width: 1280px;
  margin: $padding auto;
  padding: 0;
  border-radius: 10px;
  position: relative;
  /* @include center(); */

  @include breakpoint("max-width", $breakpoint-small) {
    width: 100%;
  }

  &Header {
    display: flex;
    background: $black;
    height: 45px;
    border-radius: 10px 10px 0 0;
    padding: 10px;
  }

  &Menu {
    width: 70px;
    display: inline-block;
    height: 25px;

    span {
      margin: 6px 4px;
      padding: 0;
      height: 12px;
      width: 12px;
      display: block;
      float: left;
      border-radius: 50%;
    }
  }

  &Close {
    background: #df7065;
  }
  &Minimize {
    background: #e6bb46;
  }
  &Maximize {
    background: #5bcc8b;
  }

  &Search {
    background: rgba($white, 0.075);
    width: 50%;
    height: 25px;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    font-weight: $font-weight--medium;
    text-shadow: 0 0 3px rgba($black, 0.5);
    @include center(true, false);

    a {
      text-decoration: none;
      color: $white;
      font-size: 12px;
      margin: 6px 10px;
      letter-spacing: 0.0225rem;
      position: absolute;
      line-height: 1;

      span {
        color: #7ed321;
      }
    }
  }

  &Window {
    width: 100%;
    height: 100%;
    overflow: hidden;
    transform: scale(1);
    border-radius: 0 0 10px 10px;
    pointer-events: none;
    aspect-ratio: 16/10;

    iframe {
      width: 100%;
      aspect-ratio: 16/10;
      border: 0;
      margin: 0 0 -6px 0;
      @include center();
    }
  }
}

.youtube {
  object-fit: cover;
  /* overflow: hidden; */
  /* width: 100%; */
  /* height: auto; */
  /* aspect-ratio: 16/10; */
  /* z-index: -1; */
  /* margin: auto; */
  /* max-width: 900px; */
  /* @include center(); */
  /* transform: scale(1.5); */
  /* overflow: hidden; */
  @include transition(all 0.666s ease-in-out);

  iframe {
    /* width: 100%; */
    /* height: calc(100% + 100px); */
    object-fit: cover;
    /* @include center(); */
  }

  &:hover,
  &:focus {
    filter: none;
  }
}

.about {
  // min-height: 100vh;
  position: relative;
  display: flex;
  align-items: flex-start;

  .article {
    width: 100%;

    &HeaderTitle {
      @include breakpoint("max-width", $breakpoint-medium) {
        margin: calc($padding * 2) 0;
      }
    }
  }

  .contentInnerDescription {
    @include breakpoint("min-width", $breakpoint-medium) {
      width: calc(100% / 2);
      margin-left: calc(100% / 3);
    }
  }
}

.contentParagraph {
  text-wrap: balance;
  font-size: 0.875rem;

  @include breakpoint("min-width", $breakpoint-medium) {
    text-align: right;
  }
}

.values {
  position: relative;
  display: flex;
  align-items: flex-start;
  width: 100%;

  a {
    color: $white;
    font-weight: $font-weight--bold;
    text-decoration: none;
  }

  @include breakpoint("max-width", $breakpoint-small) {
    flex-direction: column-reverse;
  }

  .article {
    @include breakpoint("min-width", $breakpoint-medium) {
      width: 65%;

      &Header {
        max-width: 35%;
        text-wrap: balance;
        padding-left: calc($padding * 2);

        a {
          text-decoration: none;
          color: $white;
          font-weight: $font-weight--bold;
        }
      }
    }

    &HeaderTitle {
      @include breakpoint("max-width", $breakpoint-medium) {
        margin: calc($padding * 2) 0;
      }
    }
  }

  &Spacer {
    display: block;
    height: $padding;

    @include breakpoint("min-width", $breakpoint-medium) {
      height: calc(3vh * 3);
    }
  }

  &Container {
    // mix-blend-mode: overlay;

    &Inner {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      max-width: $max-width;
      width: 100%;
      margin: auto;

      @include breakpoint("max-width", $breakpoint-medium) {
        flex-direction: column-reverse;
      }
    }
  }
}

.services {
  // min-height: 120vh;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .article {
    width: 100%;

    @include breakpoint("min-width", $breakpoint-medium) {
      &Header {
        max-width: 35%;
      }
    }

    @include breakpoint("max-width", $breakpoint-large) {
      flex-direction: column;
    }

    &Lead {
      font-size: 1.5rem;

      @include breakpoint("min-width", $breakpoint-medium) {
        padding-left: 50%;
        text-align: right;
        text-wrap: balance;
        font-size: 1.75rem;
      }

      span {
        display: inline;
        background: $white;
        color: $black;
        padding: 0 0.25rem;
        font-weight: $font-weight--medium;
      }
    }

    &HeaderTitle {
      @include breakpoint("max-width", $breakpoint-medium) {
        margin: calc($padding * 2) 0;
      }
    }
  }

  .title {
    width: calc(100% / 3);
  }

  .articleRight {
    position: relative;
    z-index: 3;
    width: 100%;

    @include breakpoint("min-width", $breakpoint-medium) {
      width: 100%;
    }
  }

  // .content {
  //   mix-blend-mode: overlay;
  // }

  &Link {
    display: inline !important;
    padding: calc($padding / 4);

    svg {
      max-width: 20px;
      fill: $white;
      margin-right: calc($padding / 4);
    }
  }
}

.container {
  // mix-blend-mode: overlay;

  &Inner {
    max-width: $max-width;
    margin: auto;
  }
}

.contact {
  // min-height: 100vh;
  position: relative;
  display: flex;
  align-items: flex-start;

  .contentInner {
    align-items: flex-start !important;
  }

  &Inner {
    /* backdrop-filter: blur(25px); */
    padding: 0;
    width: 100%;
    margin: auto;

    h3 {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    svg {
      @include transition(all 0.333s ease-in-out);
      mix-blend-mode: overlay;
    }

    a {
      @include transition(all 0.333s ease-in-out);

      &:hover,
      &:focus {
        color: $red;
        /* @include transform(scale(1.1)); */

        svg {
          fill: $red;
        }
      }
    }
  }

  &InnerHeader {
    width: 100%;
    margin: auto;
  }

  .article {
    width: 100%;

    &Header {
      width: 100%;
      max-width: 50rem;
      // padding: 20px;
      margin-bottom: calc($padding * 2);
      text-wrap: balance;

      svg {
        fill: $black;
        width: 100%;
        max-width: 42px;
        margin: 0;
        margin-bottom: calc($padding / 2);
      }
    }
  }

  .contentInnerDescription {
    @include breakpoint("min-width", $breakpoint-large) {
      width: calc(100% / 1.5);
      /* max-width: 42rem; */
      margin: auto;
    }

    @include breakpoint("min-width", $breakpoint-medium) {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      /* max-width: 42rem; */
      margin: auto;
    }
  }
}

.news {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: stretch;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  justify-content: space-between;
  border-top: $border-width solid $black;

  @include breakpoint("max-width", $breakpoint-medium) {
    overflow: hidden;
  }

  .article {
    align-items: center;

    @include breakpoint("min-width", $breakpoint-medium) {
      width: calc(100% / 3);
    }

    img {
      width: 100%;
      aspect-ratio: 2/1;
      object-fit: cover;
      border: calc($border-width / 2) solid $black;
      // filter: grayscale(100%) sepia(90%);
      @include transition(all 0.666s ease-in-out);

      &:hover,
      &:focus {
        filter: none;
      }
    }
  }
}

.logo {
  max-height: 80px;
  width: 100%;
  fill: $white;
}

.article {
  display: flex;
  align-items: stretch;
  width: 100%;
  height: 100%;

  a {
    display: block;
    width: 100%;
    padding: $foundation;
    color: $white;
    text-decoration: none;
  }

  img {
    max-width: 100%;
  }

  h2 {
    text-wrap: balance;
  }

  &Column {
    position: relative;
    width: 100%;
    margin-right: -$padding;
  }

  &Target {
    display: none;
    @include breakpoint("min-width", $breakpoint-large) {
      position: absolute;
      top: 50%;
      margin-top: -50%;
      display: block;
    }

    opacity: 0;
    background-color: $white;
    padding: $padding;
    z-index: 2;
    color: $black;
    width: 100%;
    @include box-shadow(0 0 36px -2px rgba($black, 0.25));

    img {
      aspect-ratio: 16/9;
      width: 100%;
      object-fit: cover;
    }
  }
}

.slider {
  width: 100%;
  // mix-blend-mode: overlay;

  &Slide {
    /* aspect-ratio: 4/3; */

    &Inner {
      position: relative;
      background-color: $black;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        /* @include box-shadow(0 0 36px -2px rgba($black, 0.333)); */
        /* -webkit-mask-image: -webkit-gradient(
          linear,
          left 50%,
          left bottom,
          from(rgba(0, 0, 0, 1)),
          to(rgba(0, 0, 0, 0))
        ); */
      }
    }

    &Content {
      /* position: absolute;
      bottom: 0;
      left: 0;
      right: 0; */
      /* background: rgba($black, 0.5);
      backdrop-filter: blur(25px); */
      padding: $padding 0;

      h3,
      p {
        opacity: 0;
        @include transition(all 0.333s ease-in-out);
      }

      h3 {
        margin-top: 0;
        text-wrap: balance;

        @include breakpoint("min-width", $breakpoint-medium) {
          font-size: 2.5rem;
        }
      }
    }
  }
}

.image {
  // background-color: $grey;
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: calc($border-radius / 2);
  /* aspect-ratio: 16/10; */

  @include breakpoint("max-width", $breakpoint-large) {
    margin-bottom: calc($padding / 2);
  }

  @include breakpoint("min-width", $breakpoint-medium) {
    min-width: 170px;
    max-width: 170px;
    /* aspect-ratio: $four-three; */
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.content {
  width: 100%;
  height: 100%;

  &Video {
    padding: 0;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &Inner {
    padding: $title-padding 0 calc($padding / 4) 0;

    &Media {
      padding: 0;
      aspect-ratio: 1;
      overflow: hidden;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &MediaVideo {
      padding: 0;
      aspect-ratio: $sixteen-nine;
      overflow: hidden;
      height: 100%;

      @include breakpoint("min-width", $breakpoint-medium) {
        aspect-ratio: 1;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &List {
      background-color: $black;
      padding: calc($padding * 1.5) $padding;
      padding-left: calc($padding * 2.5);
      list-style: decimal-leading-zero;
      list-style-position: outside;

      @include breakpoint("min-width", $breakpoint-medium) {
        padding: calc($padding * 2);
        padding-left: calc($padding * 3);
      }

      &:hover {
        li {
          filter: blur(4px);
        }
      }

      li {
        margin-bottom: $padding;
        padding-bottom: $padding;
        border-bottom: 1px solid rgba($white, 0.125);
        vertical-align: top;
        text-wrap: balance;
        @include transition(all 0.5s ease-in-out);

        &:last-child {
          margin-bottom: 0;
          padding-bottom: 0;
          border-bottom: 0;
        }

        &::marker {
          color: rgba($white, 0.5);
          height: 100%;
        }

        &:hover,
        &:focus {
          color: $white;
          background-color: $black;
          filter: none;

          &:not(:last-child) {
            border-bottom: 1px solid $white;
          }
        }

        h3 {
          margin: 0;
          margin-bottom: calc($padding / 4);
        }

        p {
          opacity: 0.5;
        }
      }
    }

    h3 {
      font-size: 1.5rem;
      text-wrap: balance;
    }
  }
}

.title {
  margin: 0;
  line-height: $line-height--title;
  @include line-clamp(3);
}

.description {
  font-size: 0.75rem;
  line-height: $line-height--small;
  opacity: $opacity--half;
  @include transition($animation);

  p {
    margin: 0;
    @include line-clamp(2);
  }
}

.icon {
  width: 20px;
  margin-right: calc($padding / 4);
  fill: $white;

  &Large {
    fill: $white;
    width: 4rem;
    max-height: 4rem;
    aspect-ratio: 1;
  }

  &Badge {
    margin-top: calc($padding / 2);
  }
}

.flipped {
  transform: rotateY(180deg);
}

.header {
  padding: calc($padding / 2);
  width: fit-content;
  position: relative;
  overflow-x: hidden;
  // height: 1rem;
  width: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  @include breakpoint("min-width", $breakpoint-medium) {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  h2 {
    display: flex;
    align-items: center;
    margin: 0;
    white-space: nowrap;
    padding: $padding 0;
    // height: 1rem;
    font-weight: 900;
    line-height: $line-height--small;
    font-family: $font-family--titles;
    // mix-blend-mode: multiply;

    @include breakpoint("max-width", $breakpoint-medium) {
      justify-content: center;
      font-size: 1.6rem;
      padding: 0;
    }

    img {
      max-width: 72px;
      margin: 0 $padding;
      filter: grayscale(100%) sepia(90%);
    }
  }
}
