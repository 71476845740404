@import "globals.scss";
@import "mixins.scss";

html {
  margin: 0;
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

::selection {
  background: transparent;
}

html,
body {
  font-family: $font-family;
  font-weight: $font-weight--normal;
  font-size: $font-size--body;
  font-optical-sizing: auto;
  text-rendering: optimizespeed;
  -webkit-text-size-adjust: none;
  // cursor: none;
}

body {
  /* background-color: #454443; */
  background-color: lighten($black, 12.5%);
  color: $white;
  // touch-action: none;
}

h1,
h2,
h3,
h4 {
  font-family: $font-family--titles;
  font-weight: $font-weight--normal;
  letter-spacing: -0.05rem;
  margin: calc($padding / 2) 0;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.125);

  span {
    display: block;
    font-weight: $font-weight--light;
    opacity: 0.777;
  }
}

h1 {
  font-size: 2.5rem;
  line-height: $line-height--title;
}

h2 {
  font-size: 2rem;
  line-height: $line-height--title;
}

h3 {
  font-size: 1.75rem;
  line-height: $line-height--title;
}

h4 {
  font-size: 1.25rem;
  line-height: $line-height--title;
}

p {
  margin: 0;
  // line-height: $line-height--small;
}

.main {
  position: relative;
}

.background {
  background-size: cover;
  background-position: center;
  position: fixed;
  top: -5%;
  left: -5%;
  right: -5%;
  bottom: -5%;
  opacity: 0.3;
  z-index: -1;
  filter: blur(100px);
}
