@import 'globals.scss';
@import 'mixins.scss';

.button {
  display: inline-block;
  background-color: $white;
  color: $black;
  padding: calc($padding / 2) $padding;
  border: none;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  font-weight: $font-weight--bold;
  transition: background-color 0.3s ease;

  // &:hover {
    // background-color: #0f0;
  // }
}

.hidden {
  display: none;
  position: absolute;
  top: -9999px;
  left: -9999px;
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
}